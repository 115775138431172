<template>
  <div>
    <subscribe v-if="showPlansPopup" :subscribePayload="subscribepayload" :closePopup="() => toggleSubscribePopup(false)" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Utility from "@/mixins/Utility.js";

export default {

  data() {
    return {
      subscribepayload: null,
      showPlansPopup: false,
      couponCode : null,
      planid : null
    };
  },

  computed: {
      ...mapGetters(["subscriberid", "subscriptionList"]),
  },

mounted() {
  document.title= "Unlock Premium Entertainment: Buy Saina Play Subscription Plans";     
  var allMetaElements = document.getElementsByTagName('meta');
  for(var metaValue = 0; metaValue < allMetaElements.length; metaValue ++) {
  if (allMetaElements[metaValue].getAttribute("name") == "description") { 
       allMetaElements[metaValue].setAttribute('content',"Buy the ideal Saina Play premium subscription plan for access to 500+ Malayalam Movies and Webseries."); 
  } 
 
  if(allMetaElements[metaValue].getAttribute("property") == "og:description") { 
    allMetaElements[metaValue].setAttribute('content', "Buy the ideal Saina Play premium subscription plan for access to 500+ Malayalam Movies and Webseries."); 
  } 

 if(allMetaElements[metaValue].getAttribute("name") == "twitter:description") { 
     allMetaElements[metaValue].setAttribute('content', "Buy the ideal Saina Play premium subscription plan for access to 500+ Malayalam Movies and Webseries."); 
  } 


  if(allMetaElements[metaValue].getAttribute("property") == "og:title") { 
     allMetaElements[metaValue].setAttribute('content', "Unlock Premium Entertainment: Buy Saina Play Subscription Plans"); 
  }

  if(allMetaElements[metaValue].getAttribute("name") == "twitter:title") { 
     allMetaElements[metaValue].setAttribute('content', "Unlock Premium Entertainment: Buy Saina Play Subscription Plans"); 
  }
}

    this.showPlansPopup = true;

    this.planid = this.$route.params.planId;
    this.couponCode = this.$route.params.coupon;
    let payload = {
      listPlans: "ALL",
      listType: "onlySubscribe"
    };

    this.subscribepayload = payload;

    this.toggleSubscribePopup();
  },

  methods: {

    ...mapMutations(["setPlanDeeplinkPath"]),

    toggleSubscribePopup(state) {
      if (this.subscriptionList && this.subscriptionList.length > 0) {
        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({ name: "Home", params: { lang: currentLanguage } });
      }

    },
  },

  components: {
    subscribe: () => import(/* webpackChunkName: "subscribe" */ "@/components/Popups/Subscribe.vue"),
  },
   mixins: [Utility]
};
</script>

<style></style>
